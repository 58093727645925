import React from 'react'
import PropTypes from 'prop-types'

import './SomethingWentWrong.scss'

const SomethingWentWrong = ({ children, className }) => {
  return (
    <div className={`something-went-wrong-container ${className}`}>
      <h1>
        Something Went Wrong{' '}
        <span role="img" aria-label="something went wrong">
          🙁
        </span>
        . Please check after sometime.
      </h1>
      {children}
    </div>
  )
}

SomethingWentWrong.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
}

SomethingWentWrong.defaultProps = {
  children: [],
  className: '',
}

export default SomethingWentWrong
