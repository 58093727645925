import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Layout, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { Logo, Logout } from 'components'
import './Header.scss'
import { Settings } from './Settings'

const { Header } = Layout

const HeaderMain = () => {
  const [isSettingModalVisible, setIsSettingModalVisible] = useState(false)

  return (
    <Header id="header">
      <div className="header-section">
        <div className="header-section__left">
          <Link
            to={localStorage.getItem('access-token') ? '/dashboard' : '/'}
            className="logo-section"
          >
            <Logo />
          </Link>
        </div>
        <div className="header-section__right">
          <Logout
            isSettingModalVisible={isSettingModalVisible}
            setIsSettingModalVisible={setIsSettingModalVisible}
          />
        </div>
      </div>
      <Modal
        visible={isSettingModalVisible}
        onCancel={() => setIsSettingModalVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        title={'Account settings:'}
      >
        <Settings />
      </Modal>
    </Header>
  )
}

HeaderMain.propTypes = {
  isLoggedIn: PropTypes.bool,
}

HeaderMain.defaultProps = {
  isLoggedIn: false,
}

export default HeaderMain
