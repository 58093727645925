export default (title, location, company, industry) => [
  {
    label: 'job title',
    name: 'title',
    rules: {
      message: 'Please input your title!',
      required: true,
    },
    placeholder: 'Current Title',
    initialValue: title,
  },
  {
    label: 'location',
    name: 'location',
    rules: {
      message: 'Please input your location!',
      required: true,
    },
    placeholder: 'Current Location',
    initialValue: location,
  },
  {
    label: 'company',
    name: 'company',
    rules: {
      message: 'Please input your company!',
      required: true,
    },
    placeholder: 'Current Company',
    initialValue: company,
  },
  {
    label: 'industry',
    name: 'industry',
    rules: {
      message: 'Please input your industry!',
      required: true,
    },
    placeholder: 'Current Industry',
    initialValue: industry,
  },
]
