import { get, post } from './index'

export const interestGroupSearchService = async searchTerm => {
  let response
  if (searchTerm) {
    response = await get({
      url: `/group/search?searchTerm=${searchTerm}`,
    })
  } else {
    response = await get({
      url: `/group/search?searchTearm=''`,
    })
  }

  return response.data || {}
}

export const introPreferencesService = async (userid, groupid, preference) => {
  const response = await post({
    url: `/group/intro-preferences`,
    body: {
      userid,
      groupid,
      preference,
    },
  })
  return response.data
}

export const getUserGroupService = async groupId => {
  const response = await get({
    url: `/group/detail?groupid=${groupId}`,
  })
  return response.data || {}
}

export const getMemberShowcaseService = async (groupId, userid) => {
  const response = await get({
    url: `/user/get-member-showcase/?groupId=${groupId}&userId=${userid}`,
  })
  return response.data || {}
}

export const updateGroupDetailsService = async body => {
  const response = await post({
    url: `/group/update`,
    body: body,
  })
  return response.data || {}
}

export const superAdminGroupService = async () => {
  const response = await get({
    url: `/user/super-admin-groups`,
  })
  return response.data || {}
}
export const fake = () => {}

export const getPreSignedUrlService = async file_name => {
  const response = await get({
    url: `/group-image/pre-signed-url?file_name=${file_name}`,
  })
  return response.data || {}
}

export const getPublicSignedUrlService = async file_name => {
  const response = await get({
    url: `/group-image/public-url?file_name=${file_name}`,
  })
  return response.data || {}
}

export const uploadGroupImageService = async body => {
  try {
    await post({
      url: `/group/update-image`,
      body: body,
    })
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}
