import React, { useContext, Fragment } from 'react'
import { observer } from 'mobx-react-lite'

import { Block, ProfileInfo } from 'components'

import { UserDetailContext } from 'store'
import { moreInfoText } from 'config/utils'
import './ProfileBlock.scss'

const ProfileBlock = () => {
  const { user } = useContext(UserDetailContext)

  return (
    <Block
      blockName="Profile"
      tooltipPlacement="right"
      tooltipText={moreInfoText.profile}
    >
      <Fragment>{user && <ProfileInfo profile={user} />}</Fragment>
    </Block>
  )
}

export default observer(ProfileBlock)
