import React from 'react'
import { images } from 'config/images'

const Logo = () => {
  return (
    <div className="logo">
      <img className="logo__img" src={images.app__logo} alt="logo" />
    </div>
  )
}

export default Logo
