import { post } from './index'

export const deleteAccountService = async () => {
  const response = await post({
    url: '/user/delete-profile',
  })
  return response.data || {}
}

export const fake = () => {}
