import { Button, Tooltip, Icon } from 'antd'
import React, { useState, useEffect } from 'react'
import { CopyBlock, dracula } from 'react-code-blocks'
export const CodePlugin = ({ group_id }) => {
  const [snippet, setSinppet] = useState('')
  useEffect(() => {
    setSinppet('')
  }, [group_id])
  const snippet_2 = `
    <style>
    .meeting-machine-btn {
        display: inline-block;
        background-color: white;
        background-image: url(https://meeting-machine-media.s3-us-west-2.amazonaws.com/favicon.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80px;
        height: 40px;
        width: 80px;
    }
    .meeting-machine-btn:hover {
      cursor: pointer;
    }    
    </style>
    <button class="meeting-machine-btn" onclick="return popitup('https://meetingmachine.co/group/${group_id}')"/>
    <script language="javascript" type="text/javascript">
        function popitup(url) {
          newwindow = window.open(url, 'name', 'height=800,width=800');
          if (window.focus) { newwindow.focus() }
          return false;
        }
    </script>
`
  return (
    <div>
      <div className="website-plugin">
        <div className="ant-col ant-col-5 ant-form-item-label">
          <label>Website Plugin</label>
        </div>
        <div style={{ margin: '10px' }}>
          <Tooltip
            placement="top"
            title="Amend this  J/S Snippet to your website for your intro group button to render"
          >
            <Icon type="info-circle" className="info-circle" />
          </Tooltip>
        </div>

        <input
          type="button"
          className="ant-col ant-col-16 ant-form-item-control-wrapper meeting-machine-btn"
          onClick={() => {
            setSinppet(snippet_2)
            window.open(
              `https://meetingmachine.co/group/${group_id}`,
              'x',
              'height=600,width=600,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no'
            )
          }}
        />
        {snippet && (
          <Button style={{ marginLeft: 'auto' }} onClick={() => setSinppet('')}>
            {' '}
            Hide
          </Button>
        )}
      </div>
      {snippet && (
        <div className="code-plugin">
          <CopyBlock
            language={'jsx'}
            text={snippet}
            showLineNumbers={false}
            theme={dracula}
            wrapLines={true}
            codeBlock
          />
        </div>
      )}
    </div>
  )
}
