import React, { useContext, useEffect, useState } from 'react'
import { Select, Row, Col, Form, Button, notification } from 'antd'

import { InputContainer } from 'components'
import ProfileFields from './ProfileFields'

import { UserDetailContext } from 'store'
import { observer } from 'mobx-react-lite'
import SplashScreen from 'components/SplashScreen'
import _ from 'lodash'

const Option = Select.Option

const ProfileForm = ({ form, next, closeModal }) => {
  const {
    user,
    postUserUpdate,
    search,
    searchedData,
    flushSearchedData,
  } = useContext(UserDetailContext)

  const { getFieldDecorator, getFieldsError, getFieldValue } = form
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let title = getFieldValue('title')
    let location = getFieldValue('location')
    let company = getFieldValue('company')
    let industry = getFieldValue('industry')

    if (
      _.isEmpty(_.omitBy(title, _.isNil)) ||
      _.isEmpty(_.omitBy(location, _.isNil)) ||
      _.isEmpty(_.omitBy(company, _.isNil)) ||
      _.isEmpty(_.omitBy(industry, _.isNil))
    ) {
      localStorage.setItem('profileDataChanged', true)
    } else {
      localStorage.setItem('profileDataChanged', false)
    }
  }, [form])

  const hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  const fieldSearch = async (fieldName, value) => {
    try {
      await search(fieldName, value || '')
    } catch (e) {
      const { statuscode, message } = e.response.data
      if (statuscode !== 200) {
        notification.error({
          message,
        })
      }
    }
  }

  const handleProfileSubmit = e => {
    setLoading(true)
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await postUserUpdate({
            title: values?.title?.trim(),
            location: values?.location?.trim(),
            company: values?.company?.trim(),
            industry: values?.industry?.trim(),
          })
          setLoading(false)
          closeModal()
          localStorage.removeItem('profileDataChanged')
        } catch (e) {
          const { statuscode, message } = e.response.data
          if (statuscode !== 200) {
            notification.error({
              message,
            })
          }
          setLoading(false)
        }
      }
      setLoading(false)
    })
  }

  const validations = (rule, value, callback) => {
    const onlyAllowAlphaNumericAndSpaces = /^[A-Za-z0-9 ;,._&()-éôûèàç']+$/i
    if (value?.trim()?.length === 0) {
      callback('Whitespaces are not valid.')
    } else if (value?.length >= 40) {
      callback('Maximum 40 characters only!')
    } else if (!value?.match(onlyAllowAlphaNumericAndSpaces)) {
      callback('Only alphanumeric, round brackets and spaces are allowed.')
    } else {
      callback()
    }
  }

  return (
    <>
      <Form
        className="profile-form-container"
        onSubmit={e => handleProfileSubmit(e)}
      >
        <Row gutter={24}>
          {ProfileFields(
            user?.title,
            user?.location?.[0],
            user?.company,
            user?.industry?.[0]
          ).map(field => (
            <Col
              span={
                window.innerWidth <= 850 && window.innerHeight <= 1024 ? 24 : 12
              }
              key={field.name}
            >
              <Form.Item label={field.label}>
                {getFieldDecorator(field.name, {
                  rules: [field.rules, { validator: validations }],
                  initialValue: field.initialValue,
                })(
                  field.name === 'location' || field.name === 'industry' ? (
                    <Select
                      showSearch
                      placeholder={field.placeholder}
                      style={{ width: '100%', textTransform: 'capitalize' }}
                      size="large"
                      showArrow={false}
                      onBlur={flushSearchedData}
                      getPopupContainer={trigger => trigger.parentNode}
                      onFocus={value => fieldSearch(field.name, value)}
                      dropdownRender={menu => (
                        <div>
                          <div className="fixHeightDropDown">{menu}</div>
                        </div>
                      )}
                    >
                      {searchedData.map(search => (
                        <Option
                          value={search.location || search.industry}
                          key={search.location || search.industry}
                        >
                          {search.location || search.industry}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    // ) : field.name === 'company' ? (
                    //   <Select
                    //     showSearch
                    //     placeholder={field.placeholder}
                    //     style={{ width: '100%', textTransform: 'capitalize' }}
                    //     size="large"
                    //     showArrow={false}
                    //     onBlur={flushSearchedData}
                    //     // getPopupContainer={trigger => trigger.parentNode}
                    //     onSearch={value => value.length && companySearch(value)}
                    //   >
                    //     {companySearchData.map(search => (
                    //       <Option value={search.name} key={search.name}>
                    //         {search.name}
                    //       </Option>
                    //     ))}
                    //   </Select>
                    <InputContainer
                      placeholder={field.placeholder}
                      size={field.size}
                    />
                  )
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <div className="save-button-container">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={hasErrors(getFieldsError())}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
      <SplashScreen />
    </>
  )
}

export default Form.create({ name: 'profile_form' })(observer(ProfileForm))
