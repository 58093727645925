import { observable, decorate, action } from 'mobx'
import {
  linkCalendarService,
  removeCalendarService,
} from '../services/linkCalendarService'
import { createContext } from 'react'

class LinkCalendar {
  authURL = ''
  showSplash = false

  linkCalendar = async (authName, code, userid) => {
    try {
      this.authURL = await linkCalendarService(authName, code, userid)
      this.showSplash = true
      return this.authURL
    } catch (error) {
      return null
    }
  }

  removeCalendar = async () => {
    this.authURL = await removeCalendarService()
    return this.authURL
  }

  clearSplash = async () => {
    this.showSplash = false
  }
}

decorate(LinkCalendar, {
  authURL: observable,
  showSplash: observable,
  linkCalendar: action,
  removeCalendar: action,
  clearSplash: action,
})

export default createContext(new LinkCalendar())
