import React, { Fragment, useContext, useState } from 'react'
import { Button, Input, message, Modal, notification, Tooltip } from 'antd'
import PropTypes from 'prop-types'

import { UserDetailContext } from 'store'

import { noop } from 'config/utils'
import { CopyTwoTone } from '@ant-design/icons'
const { Search } = Input

const ShareGroup = ({
  openModal,
  show,
  closeModal,
  groupToShare,
  isIntroLinkSharable,
}) => {
  const { sendEmailsViaCSV, user } = useContext(UserDetailContext)
  const [loading, setLoading] = useState(false)
  const [emails, setEmails] = useState('')
  const [file, setFile] = useState(null)
  const [isCopied, setIsCopied] = useState(false)

  //function to copy text
  const handleCopyText = async () => {
    try {
      let copyLinks = `https://${
        isIntroLinkSharable
          ? user?.introLink
          : groupToShare.groupLink || groupToShare.group_link
      }`
      navigator.clipboard.writeText(copyLinks)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    } catch (e) {
      notification.error({ message: 'Something went wrong' })
    }
  }

  const shareViaLinkedin = () => {
    window.open(
      isIntroLinkSharable
        ? `https://www.linkedin.com/shareArticle?mini=true&url=${
            user?.introLink
          }`
        : `https://www.linkedin.com/shareArticle?mini=true&url=${groupToShare.groupLink ||
            groupToShare.group_link}`,
      // `https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Flinkedin.com`,
      '_blank',
      'width=700, height=500, left=700, top=280'
    )
  }

  return (
    <Fragment>
      <Button onClick={() => openModal(true)}>Share</Button>
      <Modal
        title={isIntroLinkSharable ? 'Share Intro Link:' : 'Share Group Link'}
        centered
        visible={show}
        maskClosable
        footer={null}
        onCancel={closeModal}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div>{isIntroLinkSharable ? 'Your IntroLink:' : 'Group Link:'}</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              columnGap: '10px',
            }}
            className="copy-intro-link"
          >
            <a
              href={`https://${
                isIntroLinkSharable
                  ? user?.introLink
                  : groupToShare.groupLink || groupToShare.group_link
              }`}
            >
              {' '}
              <div id="intro-link">{`https://${
                isIntroLinkSharable
                  ? user?.introLink
                  : groupToShare.groupLink || groupToShare.group_link
              }`}</div>
            </a>

            <CopyTwoTone width={50} height={50} onClick={handleCopyText} />
            {isCopied && 'Copied'}
          </div>
          <Button
            size="large"
            style={{ width: '100%', margin: '5px' }}
            onClick={() => shareViaLinkedin()}
          >
            Share via Linkedin
          </Button>
          <Search
            style={{ margin: '5px' }}
            disabled={loading}
            type="email"
            multiple
            size="large"
            value={emails}
            placeholder="Input comma seperated emails..."
            allowClear
            enterButton="Send Emails"
            onChange={e => setEmails(e.target.value)}
            onSearch={async e => {
              try {
                if (!e) return message.info('Input comma seperated email')
                setLoading(true)
                await sendEmailsViaCSV({
                  type: isIntroLinkSharable ? 'intro_link' : 'group_link',
                  file: e,
                  group: groupToShare ? JSON.stringify(groupToShare) : null,
                })
                setEmails('')
              } catch (error) {
                console.log(error.message)
              } finally {
                setLoading(false)
              }
            }}
          />
          <Tooltip
            title={
              'Required CSV Format: One Column with Header & Email Addresses'
            }
          >
            <Search
              disabled={loading}
              style={{ margin: '5px' }}
              type="file"
              size="large"
              placeholder="Upload CSV file"
              allowClear
              enterButton="Upload CSV"
              onChange={e => setFile(e.target.files[0])}
              onSearch={async e => {
                if (file) {
                  console.log(file)
                  try {
                    setLoading(true)
                    await sendEmailsViaCSV({
                      type: isIntroLinkSharable ? 'intro_link' : 'group_link',
                      file,
                      group: groupToShare ? JSON.stringify(groupToShare) : null,
                    })
                  } catch (error) {
                    console.log(error.message)
                  } finally {
                    setLoading(false)
                    setFile(null)
                  }
                } else {
                  message.info('Select CSV file first')
                }
              }}
            />
          </Tooltip>
        </div>
      </Modal>
    </Fragment>
  )
}

ShareGroup.propTypes = {
  openModal: PropTypes.func,
  show: PropTypes.bool,
  closeModal: PropTypes.func,
}

ShareGroup.defaultProps = {
  openModal: noop,
  show: false,
  closeModal: noop,
}

export default ShareGroup
