import { post, getWithParams } from './index'

export const getAdminGroupsService = async params => {
  const response = await getWithParams({
    url: '/group/admin-groups',
    params,
  })
  return response.data || []
}

export const updateLinkedGroupService = async body => {
  const response = await post({
    url: '/group/link-groups',
    body,
  })
  return response.data || []
}

export const getAllLinkedGroups = async params => {
  const response = await getWithParams({
    url: '/group/link-groups',
    params,
  })
  return response.data || []
}
