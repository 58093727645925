import React, { Component } from 'react'
import { Input } from 'antd'

class InputContainer extends Component {
  render() {
    return <Input className={this.props.className} {...this.props} />
  }
}

export default InputContainer
