import Axios from 'axios'
import { baseUrl } from '../config/utils'

export const get = async ({ url, body }) => {
  const token = localStorage.getItem('access-token')

  const response = await Axios({
    method: 'get',
    url: `${baseUrl}${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: body || {},
  })

  return response.data
}

export const getWithParams = async ({ url, params }) => {
  const token = localStorage.getItem('access-token')

  const response = await Axios({
    method: 'get',
    url: `${baseUrl}${url}`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return response.data
}

export const post = async ({ url, body = {}, headers = {} }) => {
  const token = localStorage.getItem('access-token')

  const response = await Axios({
    method: 'post',
    url: `${baseUrl}${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: body,
  })

  return response.data
}

export const deleteReq = async ({ url }) => {
  const token = localStorage.getItem('access-token')
  const response = await Axios({
    method: 'delete',
    url: `${baseUrl}${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data
}
