import React from 'react'

import { Block, Payment } from 'components'

import { moreInfoText } from 'config/utils'
import './BillingBlock.scss'

const BillingBlock = ({ children }) => {
  return (
    <Block
      blockName="Subscription"
      tooltipPlacement="right"
      tooltipText={moreInfoText.billing}
    >
      <Payment />
    </Block>
  )
}

export default BillingBlock
