import { observable, decorate, action } from 'mobx'
import { deleteAccountService } from '../services/deleteAccountService'
import { createContext } from 'react'

class DeleteAccount {
  deleted = null

  deleteAccount = async () => {
    this.deleted = await deleteAccountService()
    return this.deleted
  }
}

decorate(DeleteAccount, {
  deleted: observable,
  deleteAccount: action,
})

export default createContext(new DeleteAccount())
