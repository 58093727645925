import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Modal, notification } from 'antd'
import { withRouter } from 'react-router-dom'
import { routes } from 'config/routes'
import './DeleteAccount.scss'

import { DeleteAccountStoreContext, UserDetailContext } from 'store'
import { isProd } from 'config/utils'

const confirm = Modal.confirm

const DeleteAccount = ({ history }) => {
  const [isAccountDeleted, setAccountDeleted] = useState(false)

  const { deleteAccount } = useContext(DeleteAccountStoreContext)

  const { user } = useContext(UserDetailContext)

  const EMAILS = [
    'parseshyam81@gmail.com',
    'parse.s@simformsolutions.com',
    'varisbhalala@outlook.com',
    'bhumin.patel19@gmail.com',
    'mapprendi@prognos.ai',
    'kcarth@gmail.com',
  ]

  const handleDeleteAccount = () => {
    confirm({
      title: 'Are you sure you want to delete this account?',
      okText: 'Yes',
      okType: 'danger',
      async onOk() {
        try {
          await deleteAccount()
          localStorage.clear()
          setAccountDeleted(true)

          return notification.success({
            message: 'Account deleted.',
          })
        } catch (e) {
          const { statuscode, message } = e.response.data

          if (statuscode !== 200) {
            return notification.error({
              message,
            })
          }
        }
      },
    })
  }

  if (isAccountDeleted) {
    if (isProd) {
      window.location.href = 'https://meetingmachine.me/'
    } else {
      return <Redirect to="/" />
    }
  }

  return (
    <>
      {EMAILS.includes(user.email) && (
        <Button
          className="updateUserBtn"
          onClick={() => {
            history.push(routes.updateUser.path)
          }}
        >
          Super Admin
        </Button>
      )}
      <Button
        className="delete-acc-btn"
        type="danger"
        onClick={handleDeleteAccount}
      >
        Delete Account
      </Button>
    </>
  )
}

export default withRouter(DeleteAccount)
