import React from 'react'

import { Block, SocialCalendar } from 'components'

import { moreInfoText } from 'config/utils'
import './LinkedBlock.scss'

const LinkedBlock = () => {
  return (
    <Block
      blockName="Linked Accounts"
      tooltipPlacement="right"
      tooltipText={moreInfoText.linkedAccounts}
    >
      <SocialCalendar />
    </Block>
  )
}

export default LinkedBlock
