import { observable, decorate, action } from 'mobx'
import {
  getMemberShowcaseService,
  superAdminGroupService,
  getPreSignedUrlService,
  uploadGroupImageService,
  getPublicSignedUrlService,
} from '../services/interestGroupSearchService'
import { createContext } from 'react'

class InterestGroup {
  members = []
  temp = []
  membersLoading = false
  superAdminGroupsData = []

  superAdminGroup = async () => {
    try {
      this.membersLoading = true
      this.superAdminGroupsData = await superAdminGroupService()
      return this.superAdminGroupsData
    } catch (error) {
      console.log(error)
    } finally {
      this.membersLoading = false
    }
  }

  updateSuperAdminGroup = async UpdateObj => {
    let { groupId, desc, pref, group_name } = UpdateObj
    try {
      const index = this.superAdminGroupsData.findIndex(
        val => val.group_id === groupId
      )
      this.superAdminGroupsData[index].description = desc
      this.superAdminGroupsData[index].is_public = pref
      this.superAdminGroupsData[index].group_name = group_name
    } catch (error) {
      console.log(error)
    } finally {
      this.membersLoading = false
    }
  }

  uploadGroupImage = async obj => {
    try {
      await uploadGroupImageService(obj)
      const index = this.superAdminGroupsData.findIndex(
        val => val.group_id === obj.groupId
      )
      this.superAdminGroupsData[index] = {
        ...this.superAdminGroupsData[index],
        group_image: obj?.group_image || '',
      }
    } catch (error) {
      console.log(error)
    }
  }

  getMemberShowcase = async (groupId, userId) => {
    try {
      this.membersLoading = true
      this.members = await getMemberShowcaseService(groupId, userId)
      this.temp = this.members
      return this.members
    } catch (error) {
      console.log(error)
    } finally {
      this.membersLoading = false
    }
  }

  getPreSignedUrl = async file_name => {
    return await getPreSignedUrlService(file_name)
  }

  getPublicSignedUrl = async (file_name, group_id) => {
    let publicUrl = await getPublicSignedUrlService(file_name)
    return publicUrl
  }

  filterMembers = name => {
    if (name) {
      name = name.trim()
      this.members = this.temp.filter(member => {
        if (
          member?.first_name?.toLowerCase().includes(name.toLowerCase()) ||
          member?.last_name?.toLowerCase().includes(name.toLowerCase()) ||
          member?.email?.toLowerCase().includes(name.toLowerCase()) ||
          member?.location?.toLowerCase().includes(name.toLowerCase())
        )
          return true
        else return false
      })
    }
  }

  clearMemberFilter = () => {
    return (this.members = this.temp)
  }
}

decorate(InterestGroup, {
  members: observable,
  membersLoading: observable,
  superAdminGroupsData: observable,
  updateSuperAdminGroup: action,
  getMemberShowcase: action,
  filterMembers: action,
  superAdminGroup: action,
  clearMemberFilter: action,
})

export default createContext(new InterestGroup())
