import React, { useContext } from 'react'
import { Card, Col, Row, Icon } from 'antd'
import { observer } from 'mobx-react-lite'
import { UserDetailContext } from 'store'
import './Welcome.scss'

const Welcome = () => {
  const { user } = useContext(UserDetailContext)
  return (
    <div className="welcome-container">
      <h2 className="card_wc_title">{`Welcome to the #${
        user.groupdetails.length ? user.groupdetails[0].group.groupName : ''
      } Intro Group on Meeting Machine!`}</h2>

      <Row gutter={45}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card
            title="Setup Requirements"
            bordered={true}
            className="welcome_card"
          >
            <div className="lioption">
              <span>
                <Icon type="check" />
              </span>
              <span className="opt_text">
                Profile Info - helps your professional match algorithm.
              </span>
            </div>
            <div className="lioption">
              <span>
                <Icon type="check" />
              </span>
              <span className="opt_text">
                Preferences - Showcase your intro objectives & match making
                rules.
              </span>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card title="How I Work" bordered={true} className="welcome_card">
            <div className="lioption">
              <span>
                <Icon type="check" />
              </span>
              <span className="opt_text">
                Ondemand - Launch anytime to meet relevant professionals 1:1
                worldwide
              </span>
            </div>
            <div className="lioption">
              <span>
                <Icon type="check" />
              </span>
              <span className="opt_text">
                Weekly curated - link your calendar to receive the most curated
                intro invitations.
              </span>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card
            title="Grow Your Intro Network"
            bordered={true}
            className="welcome_card"
          >
            <div className="lioption">
              <span>
                <Icon type="check" />
              </span>
              <span className="opt_text">
                {`Share & Embed your introLink on your social accounts.`}
              </span>
            </div>
            <div className="lioption">
              <span>
                <Icon type="check" />
              </span>
              <span className="opt_text">
                {`Create new intro groups to facilitate relevant professionals to meet.`}
              </span>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default observer(Welcome)
