import { Modal } from 'antd'
import React from 'react'
import LinkedBlock from './LinkedBlock'

const LinkedBlockModel = ({
  showLinkedBlockModal,
  setShowLinkedBlockModal,
}) => {
  return (
    <Modal
      visible={showLinkedBlockModal}
      centered
      maskClosable
      onCancel={() => setShowLinkedBlockModal(false)}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      title={'Linked Accounts:'}
    >
      <LinkedBlock />
    </Modal>
  )
}

export default LinkedBlockModel
