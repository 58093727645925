import React, { Fragment, useState, useEffect } from 'react'
import { Button, Modal, Form, Select } from 'antd'

const ShareViaEmail = ({ form, content }) => {
  const [showModal, setShowModal] = useState(false)
  const { getFieldDecorator } = form

  const handleSendEmail = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        window.location.href = `mailto:${
          values.email
        }?body=Hi, Click this link to join the group https://${content} on Meeting Machine.`
        setShowModal(false)
        form.resetFields()
      }
    })
  }

  useEffect(() => {
    return () => {
      form.resetFields()
    }
  }, [])

  const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  }

  return (
    <Fragment>
      <Button
        onClick={() =>
          (window.location.href = `mailto:?body=Hi, Click this link to join the group ${content} on Meeting Machine.`)
        }
      >
        Share via Email
      </Button>
      <Modal
        title="Share via Email:"
        centered
        visible={showModal}
        maskClosable
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <Form
          {...formItemLayout}
          onSubmit={handleSendEmail}
          labelAlign="left"
          className="create-new-group-form"
        >
          <Form.Item className="form-item" label="Email">
            {getFieldDecorator('email', {
              rules: [
                {
                  message: 'Email is required.',
                  required: true,
                },
              ],
            })(
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Email"
              />
            )}
          </Form.Item>
          <div className="create-button-container">
            <Button htmlType="submit">Send</Button>
          </div>
        </Form>
      </Modal>
    </Fragment>
  )
}

export default Form.create({ name: 'send_email' })(ShareViaEmail)
