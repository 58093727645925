import { observable, action, decorate } from 'mobx'
import {
  getUserDetailService,
  postUserDetailUpdate,
  searchService,
  companySearchService,
  getUserListService,
  exportUserService,
  getUserGroupsService,
  introPreferencesService,
  shareContactService,
  assignGroupToUserService,
  sendEmailsViaCSVService,
  getAllIntentsService,
  updateUserSkills,
  createNewIntent,
} from '../services/userDetailService'
import { introProfile } from '../services/introLinkDetailService'
import { createContext } from 'react'
import { notification } from 'antd'

class UserDetail {
  user = null
  error = null
  showSplash = false
  searchedData = []
  companySearchData = []
  userList = []
  allIntents = []
  userGroups = []
  temp = []
  introProfileDetail = null

  getIntroProfileDetails = async id => {
    this.introProfileDetail = await introProfile(id)
    return this.introProfileDetail
  }

  search = async (searchType, searchTerm) => {
    this.searchedData = await searchService(searchType, searchTerm)
    return this.searchedData
  }

  getAllIntents = async params => {
    this.allIntents = await getAllIntentsService(params)
    return this.allIntents
  }

  createNewIntent = async body => {
    this.allIntents = await createNewIntent(body)
  }

  updateUserSkills = async data => {
    const result = await updateUserSkills(data)
    if (result?.skills && result?.match_skills) {
      notification.success({ message: 'User skills & match skills updated' })
      this.user.skills = result?.skills || []
      this.user.match_skills = result?.match_skills || []
    }
  }

  getUserList = async () => {
    this.userList = await getUserListService()
    return this.userList
  }

  companySearch = async value => {
    this.companySearchData = []
    this.companySearchData = await companySearchService(value)
    return this.companySearchData
  }

  flushSearchedData = () => {
    this.searchedData = []
    this.companySearchData = []
    return this.searchedData
  }

  getUserDetail = async codeObj => {
    this.user = await getUserDetailService(codeObj)
    return this.user
  }

  getUserById = async body => {
    return await getUserDetailService(body)
  }

  exportUser = async () => {
    return await exportUserService()
  }

  postUserUpdate = async ({
    id,
    title,
    location,
    company,
    industry,
    type,
    preferredDay,
    preferredTime,
    url,
    unlimitedGroupsValue,
    maxGroupsValue,
  }) => {
    const userUpdatedValues = await postUserDetailUpdate(
      id,
      title,
      location,
      company,
      industry,
      type,
      preferredDay,
      preferredTime,
      url,
      unlimitedGroupsValue,
      maxGroupsValue
    )

    const user = {
      ...this.user,
      title: userUpdatedValues.title,
      location: userUpdatedValues.location,
      company: userUpdatedValues.company,
      industry: userUpdatedValues.industry,
      preferredDay: userUpdatedValues.preferredDay,
      preferredTime: userUpdatedValues.preferredTime,
    }

    this.user = user
    this.showSplash = true
    return this.user
  }

  modifyVerifiedPayment = (cardObj, modify) => {
    const modifiedUser = {
      ...this.user,
      isPaymentVerified: modify,
      card: cardObj,
    }

    this.user = modifiedUser

    return this.user
  }

  modifyDefaultCalendar = () => {
    const modifiedDefaultCalendar = {
      ...this.user,
      defaultCalendar: '',
    }

    this.user = modifiedDefaultCalendar

    return this.user
  }

  modifyGroupDetails = res => {
    this.user = res

    return this.user
  }

  modifyIntroPreferences = res => {
    const modifiedUser = {
      ...this.user,
      introPreference: res.introPreference,
    }

    this.user = modifiedUser

    return this.user
  }

  introPreferences = async (userid, groupid, preference) => {
    await introPreferencesService(userid, groupid, preference)
  }

  shareContactPreference = async (userId, bool, type) => {
    try {
      if (type === 'SHARE_PREFERENCE') {
        await shareContactService(userId, bool, type)
        this.user.shareContact = !bool
      } else if (type === 'INTRO_PREFERENCE') {
        await shareContactService(userId, !bool, type)
        this.user.introPreference = bool
      } else if (type === 'AUTO_ACCEPT') {
        await shareContactService(userId, !bool, type)
        this.user.auto_accept = bool
      } else if (type === 'PAUSE_INTROS') {
        await shareContactService(userId, !bool, type)
        this.user.pauseIntros = bool
      } else if (type === 'MONTHLY_INTROS') {
        await shareContactService(userId, !bool, type)
        this.user.monthlyIntros = bool
      }
      return this.user
    } catch (error) {
      console.log(error)
    }
  }
  getUserGroups = async userId => {
    this.userGroups = await getUserGroupsService(userId)
    this.temp = this.userGroups
    return this.userGroups
  }

  filterGroups = name => {
    if (name) {
      name = name.trim()
      this.userGroups = this.temp.filter(group => {
        if (group?.group_name.toLowerCase().includes(name.toLowerCase()))
          return true
        else return false
      })
    }
  }

  clearGroupFilter = () => {
    return (this.userGroups = this.temp)
  }

  assignGroupToUser = async data => {
    await assignGroupToUserService(data)
  }
  sendEmailsViaCSV = async data => {
    await sendEmailsViaCSVService(data)
  }
  clearSplash = async () => {
    this.showSplash = false
  }
}

decorate(UserDetail, {
  user: observable,
  userGroups: observable,
  showSplash: observable,
  error: observable,
  searchedData: observable,
  allIntents: observable,
  companySearchData: observable,
  introProfileDetail: observable,
  getUserGroups: action,
  shareContactPreference: action,
  flushSearchedData: action,
  search: action,
  postUserUpdate: action,
  getUserDetail: action,
  companySearch: action,
  modifyVerifiedPayment: action,
  modifyDefaultCalendar: action,
  modifyGroupDetails: action,
  modifyIntroPreferences: action,
  userList: observable,
  getUserList: action,
  introPreferences: action,
  filterGroups: action,
  clearGroupFilter: action,
  getUserById: action,
  assignGroupToUser: action,
  sendEmailsViaCSV: action,
  getAllIntents: action,
  createNewIntent: action,
  getIntroProfileDetails: action,
  clearSplash: action,
})

export default createContext(new UserDetail())
