import { observable, decorate, action } from 'mobx'
import {
  getAuthorizationService,
  getAuthURLService,
} from '../services/authorizationService'
import { createContext } from 'react'

class Authorize {
  authorized = {}

  getAuthorization = async () => {
    this.authorized = await getAuthorizationService()
    return this.authorized
  }

  getAuthURL = async authName => {
    this.authorized = await getAuthURLService(authName)
    return this.authorized
  }
}

decorate(Authorize, {
  authorized: observable,
  getAuthorization: action,
  getAuthURL: action,
})

export default createContext(new Authorize())
