import React, { useContext, useState, useEffect } from 'react'
import StripeCheckout from 'react-stripe-checkout'
import { observer } from 'mobx-react-lite'
import { notification } from 'antd'

import { Spinner } from 'components'

import { BillingStoreContext, UserDetailContext } from 'store'
import { images } from 'config/images'
import { stripeKey } from 'config/utils'

const SubscribeUser = ({ forwardRef }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { makePayment } = useContext(BillingStoreContext)
  const { modifyVerifiedPayment, user } = useContext(UserDetailContext)

  useEffect(() => {
    return () => setIsLoading(false)
  }, [])

  const onToken = async (token, addresses) => {
    setIsLoading(true)
    try {
      const res = await makePayment(
        token.id,
        token.card.brand,
        token.card.last4,
        token.email
      )
      modifyVerifiedPayment(res, true)
      notification.success({
        message: 'Subscription Successful.',
        description: 'Weekly Intros to relevent business professionals.',
      })
      window.location.href = '/dashboard'
    } catch (e) {
      setIsLoading(false)
      const { statuscode, message } = e.response.data
      if (statuscode !== 200) {
        notification.error({
          message,
        })
      }
    }
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '10vh',
        }}
      >
        <Spinner />
      </div>
    )
  }

  const { email } = user

  return (
    <StripeCheckout
      image={images.app__logo_small}
      locale="auto"
      label="Subscribe"
      panelLabel="Subscribe"
      email={email}
      allowRememberMe={false}
      // amount={1500} // cents
      currency="USD"
      ComponentClass="billing-container"
      name="Meeting Machine"
      description="$15 Monthly"
      stripeKey={stripeKey}
      token={onToken}
      triggerEvent={'onClick'}
    >
      <button ref={forwardRef} hidden={true}>
        Pay Button
      </button>
    </StripeCheckout>
  )
}

export default observer(SubscribeUser)
