export const linkedinShareService = async (uniqueID, content) => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Restli-Protocol-Version': '2.0.0',
    'Access-Control-Allow-Origin': 'no-cors',
  }
  try {
    let response = await fetch('https://api.linkedin.com/v2/ugcPosts', {
      method: 'POST',
      headers: { ...headers },
      body: JSON.stringify({
        author: `urn:li:person:${uniqueID}`,
        lifecycleState: 'PUBLISHED',
        specificContent: {
          'com.linkedin.ugc.ShareContent': {
            shareCommentary: {
              text: `Meeting Machine is a cool application that schedules 15min calls to relevant professionals for you. You recieve one currated invite each week. All you have to do is accept or decline the invite.
                  Please click here ${content} to join!  
                  Hope you give it a try!`,
            },
            shareMediaCategory: 'NONE',
          },
        },
        visibility: {
          'com.linkedin.ugc.MemberNetworkVisibility': 'PUBLIC',
        },
      }),
    })
    response = response.json()
    return response.data || {}
  } catch (e) {
    console.log({ e })
    return {}
  }
}

export const fake = () => {}
