import React from 'react'
import { Link } from 'react-router-dom'

import { isProd } from 'config/utils'

const Logout = ({ isSettingModalVisible, setIsSettingModalVisible }) => {
  const handleLogout = () => {
    localStorage.clear()
  }

  //function to open setting Modal
  const handleOpenSettings = () => {
    setIsSettingModalVisible(!isSettingModalVisible)
  }

  if (isProd) {
    return (
      <>
        <a
          className="logout-link"
          href="https://www.meetingmachine.me/faq"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Help</p>
        </a>
        <div className="logout-link">
          <div className="vl" />
        </div>
        <div className="logout-link" onClick={handleOpenSettings}>
          <p>Settings</p>
        </div>
        <div className="logout-link">
          <div className="vl" />
        </div>
        <Link to="/login" onClick={handleLogout} className="logout-link">
          <p>Logout</p>
        </Link>
      </>
    )
  }

  return (
    <>
      <a
        className="logout-link"
        href="https://www.meetingmachine.me/faq"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>Help</p>
      </a>
      <div className="logout-link">
        <div className="vl" />
      </div>
      <div className="logout-link" onClick={handleOpenSettings}>
        <p>Settings</p>
      </div>
      <div className="logout-link">
        <div className="vl" />
      </div>
      <Link to="/login" onClick={handleLogout} className="logout-link">
        <p>Logout</p>
      </Link>
    </>
  )
}

export default Logout
