import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { HeaderMain, Footer } from 'components'

const Layout = ({ header, children, footer }) => {
  return (
    <Fragment>
      {header && <HeaderMain />}
      {children}
      {footer && <Footer />}
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.bool,
  footer: PropTypes.bool,
}

Layout.defaultProps = {
  header: false,
  footer: false,
}

export default Layout
