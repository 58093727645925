import { post, get, deleteReq } from './index'
import { isProd } from 'config/utils'

export const joinGroupService = async (
  groupid,
  userid,
  lid = null,
  mg = null
) => {
  const response = await post({
    url: '/group/add-member',
    body: {
      groupid,
      userid,
      lid,
      mg,
    },
  })
  return response.data || {}
}

export const createNewGroupService = async (
  groupName,
  isPublic,
  description,
  viaSignUpCommunity
) => {
  const response = await post({
    url: '/group/create',
    body: { groupName, isPublic, description, viaSignUpCommunity },
  })
  return response.data || {}
}

export const getGrpDetailService = async groupid => {
  let response = await fetch(
    `${
      isProd ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL
    }/group/detail?groupid=${groupid}`
  )
  response = await response.json()
  console.log(response, 88)
  let publicUrl = null
  if (response && response.data && response.data.group_image) {
    const image = response.data.group_image
    const n = image.lastIndexOf('/')
    const result = image.substring(n + 1)
    publicUrl = await get({
      url: `/group-image/public-url?file_name=${result}`,
    })
    console.log(publicUrl, 33)
    response.data.group_image = publicUrl?.data || ''
  }
  return response.data || {}
}

export const updateGroupService = async (groupId, description, isPublic) => {
  const response = await post({
    url: '/group/update',
    body: { groupId, description, groupPrefernce: isPublic },
  })
  return response.data || {}
}

export const sendInvitationService = async (group_id, user_email, owner_id) => {
  const response = await post({
    url: '/group/invite-user',
    body: { group_id, user_email, owner_id },
  })
  return response.data || {}
}

export const revokeInvitationService = async (group_id, admin_id) => {
  const response = await deleteReq({
    url: `/group/revoke-invite?group_id=${group_id}&admin_id=${admin_id}`,
  })
  return response.data || {}
}

export const acceptInvitationService = async token => {
  const response = await get({
    url: `/group/accept-invite?inviteToken=${token}`,
  })
  return response.data || {}
}

export const fake = () => {}
