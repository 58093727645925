import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, notification } from 'antd'

import { AuthorizeStoreContext } from 'store'
import { images } from 'config/images'
import { isProd } from 'config/utils'

const LinkedinLogin = props => {
  const { getAuthorization } = useContext(AuthorizeStoreContext)

  const {
    linkedinLogin,
    invitedUserID,
    selectedSignupGroup,
    isMeetViaPersonalLink,
    intents,
  } = props

  const handleLogin = async () => {
    if (linkedinLogin) {
      if (isMeetViaPersonalLink) {
        localStorage.setItem('meetViaPersonalLink', isMeetViaPersonalLink)
        localStorage.setItem('Intent', JSON.stringify(intents?.IntroIntent))
      }
      try {
        const link = await getAuthorization()
        localStorage.setItem('source', link.source)
        if (props?.selectedSignupGroup) {
          localStorage.setItem('user-group-signup', props.selectedSignupGroup)
        }
        if (link.source === 'linkedin') {
          if (invitedUserID) {
            localStorage.setItem('invitedUserId', invitedUserID)
            await localStorage.setItem('groupId', selectedSignupGroup)
          }
          window.location.href = link.authUrl
        }
      } catch (e) {
        const { statuscode, message } = e.response.data
        if (statuscode === 400 || statuscode === 500 || statuscode === 404) {
          notification.error({
            message,
          })
          localStorage.clear()
          window.location.href = isProd
            ? 'https://meetingmachine.co/login'
            : '/login'
        }
      }
    } else {
      window.location.href = isProd
        ? 'https://meetingmachine.co/login'
        : '/login'
    }
  }

  return (
    <Button onClick={handleLogin} className="login-button">
      <img
        src={images.signin__button}
        alt="login button"
        className="linkedin-login"
      />
    </Button>
  )
}

export default observer(LinkedinLogin)
