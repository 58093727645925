import React from 'react'
import { images } from 'config/images'
import './Login.scss'

const Page_404 = ({ history }) => {
  return (
    <>
      <div className="intermediate-container">
        <div className="page__content">
          <div className="logo__container">
            <img
              src={images.app__logo}
              alt="app__logo"
              className="grp__page__logo"
            />
          </div>
          <div className="card">
            <div className="card__content">
              <div className="card__title">
                <p className="card__title__text">This is an inactive page</p>
              </div>
              <hr />
              <div className="card__description">
                <p className="card__desc__text">
                  WE COULDN'T FIND THE PAGE YOU WERE LOOKING FOR. THIS IS EITHER
                  BECAUSE
                </p>
              </div>

              <div className="card__description">
                <h4 className="card__desc__text">
                  • THERE IS AN ERROR IN THE URL ENTERED INTO YOUR WEB BROWSER.
                  PLEASE CHECK THE URL AND TRY AGAIN.
                </h4>
              </div>

              <div className="card__description">
                <p className="card__desc__text">
                  • THE PAGE YOU ARE LOOKING FOR HAS BEEN MOVED OR DELETED.
                </p>
              </div>
              <div className="mm__link">
                <p>
                  YOU CAN RETURN TO OUR HOMEPAGE BY{' '}
                  <a
                    className="mm__link__link"
                    href="https://meetingmachine.me"
                    rel="noopener noreferrer"
                  >
                    CLICKING HERE
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Page_404
