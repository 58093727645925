import { observable, decorate, action } from 'mobx'
import {
  interestGroupSearchService,
  introPreferencesService,
  getUserGroupService,
  updateGroupDetailsService,
} from '../services/interestGroupSearchService'
import { createContext } from 'react'
import { notification } from 'antd'
class InterestGroup {
  groups = []
  groupDetail = {}
  groupAdminsIncludingOwner = {}
  groupLoading = false

  interestGroupSearch = async searchTerm => {
    try {
      this.groupLoading = true
      this.groups = await interestGroupSearchService(searchTerm)
      return this.groups
    } catch (error) {
      console.log(error)
    } finally {
      this.groupLoading = false
    }
  }
  getUserGroupDetail = async groupId => {
    try {
      this.groupLoading = true
      this.groupDetail = await getUserGroupService(groupId)
      if (
        this.groupDetail &&
        this.groupDetail.group_owner &&
        this.groupDetail.invitedAdmins
      ) {
        const {
          id,
          firstName,
          lastName = '',
          profileUrl,
          linkedinProfileUrl,
          authDetails,
          email,
        } = this.groupDetail.group_owner
        this.groupAdminsIncludingOwner = [
          ...this.groupDetail.invitedAdmins,
          {
            admin_id: id,
            admin_name: `${firstName} ${lastName}`,
            email: authDetails[0].email || email,
            linkedin_url: linkedinProfileUrl,
            profile_url: profileUrl,
          },
        ]
      }
      return this.groupDetail
    } catch (error) {
      console.log(error)
    } finally {
      this.groupLoading = false
    }
  }

  updateGroupImage = async imageUrl => {
    this.groupDetail = {
      ...this.groupDetail,
      group_image: imageUrl,
    }
  }

  updateGroupDetail = async body => {
    try {
      this.groupLoading = true
      await updateGroupDetailsService(body)
      this.groupDetail.description = body.description
      this.groupDetail.isPublic = body.groupPrefernce
      this.groupDetail.groupName = body.group_name
      notification.success({
        message: 'Your group is updated',
      })
      return true
    } catch (error) {
      notification.error({
        message: 'Group name is already taken',
      })
      console.log(error)
    } finally {
      this.groupLoading = false
    }
  }

  introPreferences = async (userid, groupid, preference) => {
    return await introPreferencesService(userid, groupid, preference)
  }
}

decorate(InterestGroup, {
  groups: observable,
  groupLoading: observable,
  groupDetail: observable,
  groupAdminsIncludingOwner: observable,
  getUserGroupService: action,
  interestGroupSearch: action,
  introPreferences: action,
  updateGroupDetail: action,
})

export default createContext(new InterestGroup())
