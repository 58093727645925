import React, { useState, useContext, useEffect } from 'react'
import { Table, Button, Modal, Input, notification, Popconfirm } from 'antd'
import moment from 'moment'
import { UserDetailContext } from 'store'
import LinkedGroups from '../LinkedGroups'
const { Search } = Input
export const GroupsIntroIntentsListing = ({ group }) => {
  const [showCreateIntentModal, setShowCreateIntentModal] = useState(false)

  const { allIntents, getAllIntents, createNewIntent } = useContext(
    UserDetailContext
  )

  const [editIntent, setEditIntent] = useState(null)
  const [intent, setIntent] = useState('')
  const [loading, setLoading] = useState(false)
  const [premiumUserWaringModel, setPremiumUserWaringModel] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        await getAllIntents({ group_id: group.id })
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const userGroupColumn = [
    {
      title: 'Intent',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: 'Created At',
      align: 'center',
      dataIndex: 'createdAt',
      render: value => moment(value).format('DD MMM YYYY'),
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          {group.isPremium ? (
            <Button
              style={{ margin: '2px' }}
              onClick={() => {
                setShowCreateIntentModal(true)
                setEditIntent(record)
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              style={{ margin: '2px' }}
              onClick={() => setPremiumUserWaringModel(true)}
            >
              Edit
            </Button>
          )}

          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={async () => {
              try {
                const data = {
                  type: 'DELETE',
                  id: record.id,
                  group_id: group.id,
                }
                setLoading(true)
                await createNewIntent(data)
                setShowCreateIntentModal(false)
                notification.success({ message: 'Intent deleted successfully' })
              } finally {
                setLoading(false)
              }
            }}
          >
            <Button danger style={{ margin: '2px' }}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ]
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          margin: '2px',
        }}
      >
        <LinkedGroups group={group} />
        <h4>Your group's intro intents</h4>
        <Table
          style={{
            height: '180px',
            overflow: 'scroll',
            width: '-webkit-fill-available',
            margin: '2px',
          }}
          bordered
          loading={loading}
          scroll={{ x: 400 }}
          pagination={false}
          rowKey={'id'}
          columns={userGroupColumn}
          dataSource={allIntents}
        />
        {group.isPremium ? (
          <Button
            style={{ margin: '2px' }}
            onClick={() => setShowCreateIntentModal(true)}
          >
            Create +{' '}
          </Button>
        ) : (
          <Button
            style={{ margin: '2px' }}
            onClick={() => setPremiumUserWaringModel(true)}
          >
            Create +{' '}
          </Button>
        )}
      </div>
      <Modal
        title={editIntent ? 'Edit Intent' : 'Create New Intent'}
        footer={false}
        visible={showCreateIntentModal}
        onOk={console.log}
        onCancel={() => {
          setShowCreateIntentModal(false)
          setEditIntent(null)
        }}
      >
        Group Intro Intent
        <Search
          placeholder="input search text"
          allowClear
          enterButton={editIntent ? 'Save' : 'Create'}
          size="large"
          value={intent || (editIntent && editIntent?.name)}
          onChange={e => setIntent(e.target.value)}
          onSearch={async value => {
            const data = {
              group_id: group.id,
              name: value,
              id: editIntent?.id || null,
            }
            try {
              setLoading(true)
              await createNewIntent(data)
              setShowCreateIntentModal(false)
              notification.success({
                message: editIntent?.id
                  ? 'Intent updated successfully'
                  : 'Intent created successfully',
              })
            } catch (e) {
              const { statuscode, message } = e.response.data
              if (statuscode !== 200) {
                return notification.error({
                  message,
                })
              }
            } finally {
              setLoading(false)
            }
          }}
        />
      </Modal>
      <Modal
        title="Unlock Premium Community Building Features"
        centered
        width={500}
        visible={premiumUserWaringModel}
        onCancel={() => setPremiumUserWaringModel(false)}
        footer={null}
      >
        <>
          <div className="container">
            <p style={{ fontSize: '16px', marginBottom: '10px' }}>
              Schedule a call with our solutions team to discuss best options
              and platform features to harness for your community building
              goals.
            </p>
            <Button>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/meeting-machine/meeting-machine-intros-demo"
              >
                Schedule Call
              </a>
            </Button>
          </div>
        </>
      </Modal>
    </>
  )
}
