import React, { useContext, forwardRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal, notification, Radio, Divider } from 'antd'

import {
  PaymentCardInformation,
  // CancelSubscription,
  SubscribeUser,
  UpdateCard,
} from 'components'

import { UserDetailContext, BillingStoreContext } from 'store'

const confirm = Modal.confirm
const Payment = () => {
  const { user, modifyVerifiedPayment } = useContext(UserDetailContext)
  const { cancelSubscription } = useContext(BillingStoreContext)
  const subscribe = forwardRef()
  const updateCard = forwardRef()
  const handleCancelSubscription = () => {
    confirm({
      title: 'Are you sure you want to move to the Free Monthly Plan?',
      okText: 'Yes',
      okType: 'danger',
      async onOk() {
        try {
          const res = await cancelSubscription()
          modifyVerifiedPayment({}, false)
          notification.success({
            message: 'Subscription Cancelled.',
            description: res,
          })
          window.location.href = '/dashboard'
        } catch (e) {
          const { statuscode, message } = e.response.data
          if (statuscode !== 200) {
            notification.error({
              message,
            })
          }
        }
      },
    })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          marginTop: '10px',
        }}
      >
        <Radio.Group
          name="radiogroup"
          defaultValue={1}
          style={{ marginTop: '5px' }}
        >
          <Radio
            value="free"
            onChange={handleCancelSubscription}
            checked={user && !user.isPaymentVerified ? true : false}
          >
            <label>
              <b>{` Free Monthly `}</b>
            </label>
          </Radio>
          <Radio
            value="paid"
            onClick={() => {
              if (subscribe.current) subscribe.current.click()
            }}
            checked={user && user.isPaymentVerified ? true : false}
          >
            <label>
              <b>{` $15 Monthly `}</b>
            </label>
          </Radio>
        </Radio.Group>
      </div>
      <div>
        {user?.isPaymentVerified ? (
          <>
            <Divider />
            <div className="payment-container">
              {/* <CancelSubscription handleCancelSubscription={handleCancelSubscription} /> */}
              <UpdateCard forwardRef={updateCard} />
              <PaymentCardInformation card={user.card} />
            </div>
          </>
        ) : (
          <SubscribeUser forwardRef={subscribe} />
        )}
      </div>
    </>
  )
}

export default observer(Payment)
