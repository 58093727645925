import React, { useEffect, useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Modal, Transfer } from 'antd'
import { GroupLinkContext } from 'store'

import './index.scss'
const LinkedGroups = ({ group }) => {
  // const { user } = useContext(UserDetailContext)
  const {
    adminGroups,
    linkedGroups,
    getAdminGroups,
    updateLinkedGroup,
    loadingAdminGroups,
  } = useContext(GroupLinkContext)
  const [premiumUserWaringModel, setPremiumUserWaringModel] = useState(false)

  useEffect(() => {
    getAdminGroups({
      admin_id: group.admin_id,
      group_id: group.id,
    })
  }, [])

  useEffect(() => {
    setTargetKeys(linkedGroups)
  }, [linkedGroups])

  const [targetKeys, setTargetKeys] = React.useState([])

  const onChange = (newTargetKeys, direction, moveKeys) => {
    if (group.isPremium) {
      setTargetKeys(newTargetKeys)
      updateLinkedGroup({
        admin_id: group.admin_id,
        group_id: group.id,
        linked_groups: newTargetKeys,
      })
    } else {
      setPremiumUserWaringModel(true)
    }
  }
  if (loadingAdminGroups) return <p>Loading...</p>
  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Transfer
          titles={['Your Managed Groups', `Linked to ${group?.groupName}`]}
          dataSource={adminGroups}
          targetKeys={targetKeys}
          onChange={onChange}
          render={item => item?.title}
          pagination
        />
        <Modal
          title="Unlock Premium Community Building Features"
          centered
          width={500}
          visible={premiumUserWaringModel}
          onCancel={() => setPremiumUserWaringModel(false)}
          footer={null}
        >
          <>
            <div className="container">
              <p style={{ fontSize: '16px', marginBottom: '10px' }}>
                Schedule a call with our solutions team to discuss best options
                and platform features to harness for your community building
                goals.
              </p>
              <Button>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://calendly.com/meeting-machine/meeting-machine-intros-demo"
                >
                  Schedule Call
                </a>
              </Button>
            </div>
          </>
        </Modal>
      </div>
    </>
  )
}

export default observer(LinkedGroups)
