import React from 'react';
import PropTypes from 'prop-types';

import './VideoBackground.scss';

const VideoBackground = ({ videoSrc, autoPlay, loop, muted, videoType }) => {
  return (
    <video muted={muted} autoPlay={autoPlay} loop={loop} id="video-background">
      <source
        src={videoSrc || 'http://teragigame.ga/files/madcowfilms.webm'}
        type={videoType}
      />
    </video>
  )
}

VideoBackground.propTypes = {
  videoSrc: PropTypes.string,
  videoType: PropTypes.string,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
}

VideoBackground.defaultProps = {
  videoSrc: '',
  videoType: '',
  autoPlay: false,
  loop: false,
  muted: false,
}

export default VideoBackground;
