import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { NoInternetConnection } from 'components'
import { io } from 'sailsConnect'
import { renderRoutes } from 'config/routes'
import PAGE_404 from '../pages/Page404/Page_404'

const App = () => {
  io.socket.on('connect', function onConnect() {
    console.log('This socket is now connected to the Sails server!')
  })
  if (window.navigator) {
    const online = window.navigator.onLine
    if (!online) {
      return <NoInternetConnection />
    }
  }
  return (
    <Router>
      <Suspense fallback={<div />}>
        <Switch>
          {renderRoutes.map(([k, v]) => (
            <Route
              path={v.path}
              exact={v.exact}
              component={v.component}
              key={v.path}
            />
          ))}
          <Route component={PAGE_404} key={404} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
