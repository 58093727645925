import React, { useContext, useState } from 'react'
import {
  Form,
  Button,
  Input,
  message,
  Modal,
  Upload,
  Popconfirm,
  Icon,
} from 'antd'
import { observer } from 'mobx-react-lite'
import {
  InterestGroupStoreContext,
  JoinGroupStoreContext,
  UserDetailContext,
  MemberShowcase,
} from 'store'
import { GroupsIntroIntentsListing } from '../CreateNewIntent/index'
import axios from 'axios'
import './index.scss'
import { AdminListComponent } from './AdminListComponent'
import { CreateMatchmakerRules } from 'components/CreateMatchmakerRules'
const { Search } = Input
const GroupSettingModal = ({
  form,
  showImageModal,
  setShowImageModal,
  image,
  setImage,
}) => {
  const { getFieldDecorator } = form
  const { user } = useContext(UserDetailContext)
  const { sendInvitation } = useContext(JoinGroupStoreContext)
  const { uploadGroupImage, getPreSignedUrl } = useContext(MemberShowcase)
  const { groupDetail, updateGroupImage } = useContext(
    InterestGroupStoreContext
  )
  const [premiumUserWaringModel, setPremiumUserWaringModel] = useState(false)

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const handleChange = info => {
    getBase64(info, imageUrl => {
      setImage({
        ...image,
        imageUrl,
      })
    })
    return false
  }
  const handleImageUpload = e => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      try {
        if (!values?.image?.file?.name) {
          return message.info('Select file first.')
        }
        setImage({ ...image, loading: true })
        const file_name = `${Date.now()}_${values.image.file.name}`
        const url = await getPreSignedUrl(file_name)
        const uploaded = await axios.put(url, values.image.file)
        if (uploaded) {
          let image_name = `https://${process.env.REACT_APP_BUCKET}.s3-${
            process.env.REACT_APP_REGION
          }.amazonaws.com/${file_name}`
          await uploadGroupImage({
            groupId: groupDetail.id,
            group_image: image_name,
          })
          updateGroupImage(image_name)
          message.success('Group image uploaded')
        }
      } catch (error) {
        message.error('Error occured while uploading Group Image')
        console.log(error)
      } finally {
        setImage({ ...image, loading: false })
        setShowImageModal(false)
      }
    })
  }

  const sendUserInvitation = async emailAddress => {
    if (groupDetail.isPremium) {
      let email = emailAddress.trim()
      if (!email) return
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!re.test(String(email).toLowerCase())) {
        return message.error('Please enter valid email address.')
      }
      try {
        if (email === user.email)
          return message.warning('You cannot invite yourself')
        setShowImageModal(false)
        await sendInvitation(groupDetail.id, email, user.id)
        message.success('Invitaion send to : ' + email)
      } catch (error) {
        message.info(error.message)
        setShowImageModal(false)
      }
    } else {
      setPremiumUserWaringModel(true)
    }
  }
  const removeImage = async () => {
    try {
      if (!groupDetail.group_image) return
      setImage({ ...image, imageUrl: null })
      await uploadGroupImage({
        groupId: groupDetail.id,
        group_image: '',
      })
      updateGroupImage('')
      message.success('Group image removed')
    } catch (error) {
      message.error('Error occured while removing image')
    } finally {
      setShowImageModal(false)
    }
  }
  return (
    <Modal
      title={'Group Settings: ' + groupDetail.groupName}
      centered
      width={800}
      visible={showImageModal}
      maskClosable
      footer={null}
      destroyOnClose
      onCancel={() => {
        setImage({ imageUrl: '', loading: false })
        setShowImageModal(false)
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Form onSubmit={handleImageUpload}>
          <div className="centered">
            <Form.Item label="UPLOAD PICTURE">
              {getFieldDecorator('image', {
                valuePropName: 'adPics',
              })(
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={handleChange}
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                >
                  {' '}
                  {image.imageUrl ? (
                    <img
                      src={image.imageUrl}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <div>
                      <Icon type={image.loading ? 'loading' : 'plus'} />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload>
              )}
            </Form.Item>
            <Button
              style={{ marginRight: '5px' }}
              loading={image.loading}
              htmlType="submit"
            >
              Upload New
            </Button>
            <Popconfirm
              style={{ marginRight: '5px' }}
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                form.setFieldsValue.image = null
                removeImage()
              }}
            >
              <Button disabled={image.loading} type="danger">
                Remove
              </Button>
            </Popconfirm>
          </div>
        </Form>
      </div>
      {(user?.id === groupDetail?.admin_id ||
        user?.id === groupDetail?.userId ||
        groupDetail.invitedAdmins?.some(
          adminObj => adminObj.admin_id === user.id
        )) && <GroupsIntroIntentsListing group={groupDetail} />}
      {(user?.id === groupDetail?.admin_id ||
        user?.id === groupDetail?.userId ||
        groupDetail.invitedAdmins?.some(
          adminObj => adminObj.admin_id === user.id
        )) && <CreateMatchmakerRules group={groupDetail} />}
      <div>
        <b>Group Administrators:</b>
        {groupDetail.userId === user.id && (
          <Search
            placeholder="Enter Email to Invite New Admin"
            allowClear
            enterButton="Invite"
            size="default"
            onSearch={sendUserInvitation}
          />
        )}
      </div>
      <Modal
        title="Unlock Premium Community Building Features"
        centered
        width={500}
        visible={premiumUserWaringModel}
        onCancel={() => setPremiumUserWaringModel(false)}
        footer={null}
      >
        <>
          <div className="container">
            <p style={{ fontSize: '16px', marginBottom: '10px' }}>
              Schedule a call with our solutions team to discuss best options
              and platform features to harness for your community building
              goals.
            </p>
            <Button>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/meeting-machine/meeting-machine-intros-demo"
              >
                Schedule Call
              </a>
            </Button>
          </div>
        </>
      </Modal>
      <AdminListComponent setShowImageModal={setShowImageModal} />
    </Modal>
  )
}
export default Form.create()(observer(GroupSettingModal))
