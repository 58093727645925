import { observable, decorate, action } from 'mobx'
import { linkedinShareService } from '../services/linkedinShareService'
import { createContext } from 'react'

class LinkedinShare {
  share = null

  shareOnLinkedin = async (uniqueID, content) => {
    this.share = await linkedinShareService(uniqueID, content)
    return this.share
  }
}

decorate(LinkedinShare, {
  share: observable,
  shareOnLinkedin: action,
})

export default createContext(new LinkedinShare())
