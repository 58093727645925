import React, { useContext, useState, useEffect } from 'react'
import { Form, Button, Input, Table, Modal } from 'antd'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import MemberShowCase from './MemberShowCaseModal'
import GroupInfo from './GroupInfo'
import {
  InterestGroupStoreContext,
  UserDetailContext,
  MemberShowcase,
} from 'store'
import './index.scss'
import { CreateNewGroup } from 'components'

const { Search } = Input
const GroupManager = () => {
  const [showMembers, setShowMembers] = useState(false)
  const [showNewGroupModal, setShowNewGroupModal] = useState(false)
  const [showMyGroupInfoModal, setShowMyGroupInfoModal] = useState(false)
  const [premiumUserWaringModel, setPremiumUserWaringModel] = useState(false)
  const [searchText, setSearchText] = useState('')
  const {
    user,
    userGroups,
    getUserGroups,
    filterGroups,
    clearGroupFilter,
  } = useContext(UserDetailContext)

  const { getMemberShowcase } = useContext(MemberShowcase)
  const { getUserGroupDetail } = useContext(InterestGroupStoreContext)

  useEffect(() => {
    getUserGroups(user.id)
  }, [])
  const handleClickedMembers = (option, openInfoModal = false) => {
    if (openInfoModal) {
      setShowMembers(true)
    } else {
      setShowMembers(false)
    }
  }

  const handleClickedUserGroup = (option, openInfoModal = false) => {
    setShowNewGroupModal(false)
    setShowMyGroupInfoModal(true)
  }

  const userGroupColumn = [
    {
      title: 'GROUP NAME',
      align: 'center',
      dataIndex: 'group_name',
      render(key, record) {
        return (
          <Button
            type="link"
            onClick={() => {
              getUserGroupDetail(record.group_id)
              handleClickedUserGroup({}, true)
            }}
          >
            {record.group_name}
          </Button>
        )
      },
    },
    {
      title: 'MEMBERS',
      align: 'center',
      dataIndex: 'members_count',
      render(key, record) {
        return (
          <Button
            type="link"
            onClick={() => {
              getMemberShowcase(record.group_id, user.id)
              handleClickedMembers({}, true)
            }}
          >
            {record.members_count}
          </Button>
        )
      },
    },
    {
      title: 'CREATED',
      align: 'center',
      dataIndex: 'created_at',
      render(val) {
        return <p>{moment(parseInt(val)).calendar()}</p>
      },
    },
  ]
  const handleNewGroup = (group, openInfoModal = false) => {
    setShowNewGroupModal(false)
    setShowMyGroupInfoModal(true)
    getUserGroupDetail(group.id)
    return (
      <GroupInfo
        showMyGroupInfoModal={showMyGroupInfoModal}
        setShowMyGroupInfoModal={setShowMyGroupInfoModal}
      />
    )
  }
  return (
    <>
      <MemberShowCase
        showMembers={showMembers}
        setShowMembers={setShowMembers}
      />
      <GroupInfo
        showMyGroupInfoModal={showMyGroupInfoModal}
        setShowMyGroupInfoModal={setShowMyGroupInfoModal}
      />
      <Modal
        title="Create New Group:"
        centered
        visible={showNewGroupModal}
        maskClosable
        footer={null}
        destroyOnClose
        onCancel={() => setShowNewGroupModal(false)}
      >
        <CreateNewGroup handleNewGroup={handleNewGroup} />
      </Modal>

      {/* Group Paywall - user notification */}
      <Modal
        title="Unlock Premium Community Building Features"
        centered
        width={500}
        visible={premiumUserWaringModel}
        onCancel={() => setPremiumUserWaringModel(false)}
        footer={null}
      >
        <>
          <div className="container">
            <p style={{ fontSize: '16px', marginBottom: '10px' }}>
              Schedule a call with our solutions team to discuss best options
              and platform features to harness for your community building
              goals.
            </p>
            <Button>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/meeting-machine/meeting-machine-intros-demo"
              >
                Schedule Call
              </a>
            </Button>
          </div>
        </>
      </Modal>

      <div className="search-bar-block">
        <div className="search-bar-column">
          <Search
            className="search-input"
            value={searchText}
            placeholder="Your Managed Groups​"
            onChange={e => {
              filterGroups(e.target.value)
              setSearchText(e.target.value)
            }}
          />
          <Button
            className="search-input-clear"
            type="danger"
            onClick={() => {
              clearGroupFilter()
              setSearchText('')
            }}
          >
            Clear
          </Button>
          <Button
            className="create-new-group-btn"
            type="primary"
            onClick={() => {
              if (
                !user.unlimitedGroups &&
                user.maxGroups <= userGroups.length
              ) {
                setPremiumUserWaringModel(true)
              } else {
                setShowNewGroupModal(true)
              }
            }}
          >
            + New
          </Button>
        </div>
      </div>
      <Table
        style={{
          height: '163px',
          overflow: 'scroll',
          width: '-webkit-fill-available',
        }}
        loading={Boolean(!userGroups)}
        bordered
        scroll={{ x: 400 }}
        pagination={false}
        rowKey={'group_id'}
        columns={userGroupColumn}
        dataSource={userGroups}
      />
    </>
  )
}
export default Form.create()(observer(GroupManager))
