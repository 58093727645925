import React from 'react'

import './NoInternetConnection.scss'

const NoInternetConnection = () => {
  return (
    <div className="no-internet-connection-container">
      <h1>No Internet Connection. :(</h1>
      <p>Check your Internet Connection or Please try again later.</p>
    </div>
  )
}

export default NoInternetConnection
