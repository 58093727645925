import React from 'react'
import { Spin, Icon } from 'antd'

const LoadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

const Spinner = ({ spinning }) => {
  return <Spin spinning={spinning} indicator={LoadingIcon} />
}

export default Spinner
