import { observable, decorate, action } from 'mobx'
import {
  getAdminGroupsService,
  updateLinkedGroupService,
  getAllLinkedGroups,
} from '../services/groupLinkService'
import { createContext } from 'react'

class LinkGroup {
  adminGroups = []
  linkedGroups = []
  loadingAdminGroups = false

  getAdminGroups = async params => {
    this.loadingAdminGroups = true
    try {
      const { adminGroups, linkedGroups } = await getAdminGroupsService(params)
      this.adminGroups = adminGroups.sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
        return 0
      })
      this.linkedGroups = linkedGroups
    } finally {
      this.loadingAdminGroups = false
    }
  }

  updateLinkedGroup = async body => {
    this.loadingAdminGroups = true
    try {
      await updateLinkedGroupService(body)
    } finally {
      this.loadingAdminGroups = false
    }
  }

  getAllLinkedGroups = async params => {
    this.loadingAdminGroups = true
    try {
      this.linkedGroups = await getAllLinkedGroups(params)
    } finally {
      this.loadingAdminGroups = false
    }
  }
}

decorate(LinkGroup, {
  adminGroups: observable,
  linkedGroups: observable,
  loadingAdminGroups: observable,
  getAdminGroups: action,
  getAllLinkedGroups: action,
})

export default createContext(new LinkGroup())
