import React from 'react'
import PropTypes from 'prop-types'
import { Card, Icon, Tooltip } from 'antd'

import './Block.scss'

const Block = ({
  children,
  style,
  blockName,
  tooltipPlacement,
  tooltipText,
}) => {
  return (
    <Card style={{ ...style }} className="block-container">
      <div className="block-title">
        <h2>{blockName}</h2>
        <Tooltip placement={tooltipPlacement} title={tooltipText}>
          <Icon type="info-circle" className="info-circle" />
        </Tooltip>
      </div>
      <div className="block-content">{children}</div>
    </Card>
  )
}

Block.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  blockName: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  tooltipText: PropTypes.string,
}

Block.defaultProps = {
  style: {},
  blockName: '',
  tooltipPlacement: 'top',
  tooltipText: '',
}

export default Block
