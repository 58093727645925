import { post } from './index'

export const linkCalendarService = async (authName, code, userid) => {
  const response = await post({
    url: `/${authName}/list-all-events`,
    body: {
      code,
      userid,
    },
  })
  return response.data || {}
}

export const removeCalendarService = async () => {
  const response = await post({
    url: '/user/delete/default-calendar',
  })
  return response.data || {}
}

export const fake = () => {}
