import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'

const ProfileAvatar = ({ profileUrl }) => {
  return (
    <Fragment>
      {profileUrl ? (
        <Avatar size={100} src={profileUrl} />
      ) : (
        <Avatar size={100} icon="user" />
      )}
    </Fragment>
  )
}

ProfileAvatar.propTypes = {
  profileUrl: PropTypes.string,
}

ProfileAvatar.defaultProps = {
  profileUrl: '',
}

export default ProfileAvatar
