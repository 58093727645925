import { get } from './index'

export const introProfile = async introID => {
  const res = get({
    url: `/user/short-info`,
    body: {
      name: introID,
    },
  })
  return res || {}
}
