import React from 'react'
import PropTypes from 'prop-types'

// import './PaymentCardInformation.scss'

const PaymentCardInformation = ({ card }) => {
  return (
    <div style={{ marginLeft: '20px' }}>
      <div>
        <p>
          <b>{card.brand}</b> <b>{`#${card.last4}`}</b>
        </p>
      </div>
      <div />
    </div>
  )
}

PaymentCardInformation.propTypes = {
  card: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default PaymentCardInformation
