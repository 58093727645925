export const noop = () => {}

export const fake = {}

export const moreInfoText = {
  profile:
    'Your work background helps us match you with relevant professionals to meet.​',
  linkedAccounts:
    'Your schedule helps us determine mutual availability to book meetings with relevant professionals for you.​',
  billing: 'Free: Up to 5 intros monthly. Paid: Unlimited intros monthly.',
  interestGroup: `Join a relevant intro group, personalize intro preferences and share your IntroLink to grow your network.`,
}

export const isEmoji = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g

export const isProd = process.env.NODE_ENV === 'production'

export const sailsURL = isProd
  ? process.env.REACT_APP_PROD_SAILS_URL
  : process.env.REACT_APP_DEV_SAILS_URL

export const baseUrl = isProd
  ? process.env.REACT_APP_PROD_URL
  : process.env.REACT_APP_DEV_URL

export const stripeKey = isProd
  ? process.env.REACT_APP_PROD_STRIPE_KEY
  : process.env.REACT_APP_DEV_STRIPE_KEY
