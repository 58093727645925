import { Modal } from 'antd'
import React from 'react'
import './MeetingReminderModal.scss'
import { Link } from 'react-router-dom'

const MeetingReminderModal = ({ isOpen, setIsOpen, meetingDetails }) => {
  const { firstName, lastName, pin } = meetingDetails || {}

  return (
    <Modal
      title={false}
      centered
      width={600}
      visible={isOpen}
      footer={null}
      onCancel={() => setIsOpen(false)}
    >
      <div className="header">
        <h2 className="meeting-reminder-modal-title">
          You have a Scheduled 1:1 Intro now!
        </h2>
        <hr className="green-line-break" />
      </div>
      <div className="meeting-reminder-modal-content">
        <h3>
          You are scheduled to meet {firstName} {lastName}. Please join by
          entering the 1:1 meeting room below.
        </h3>
        <button className="onDemand-Btn onDemandBtnRow">
          <Link to={`/conference/${pin}`}> Enter 1:1 Meeting Room</Link>
        </button>
      </div>
    </Modal>
  )
}

export default MeetingReminderModal
