import React, { Fragment, useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import { ProfileForm } from 'components'

const OnboardingUserModal = ({ profile, show, openModal, closeModal }) => {
  const [showPermissionErrorModal, setShowPermissionErrorModal] = useState(
    false
  )
  const checkCalendarLinkError = () => {
    if (profile) {
      const isAttemptMade = localStorage.getItem('calendar_link_attempt')
      if (
        isAttemptMade &&
        Number(isAttemptMade) < Date.now() &&
        !profile.defaultCalendar
      ) {
        setShowPermissionErrorModal(true)
      }
      localStorage.removeItem('calendar_link_attempt')
    }
  }
  useEffect(() => checkCalendarLinkError(), [])
  return (
    <Fragment>
      <Button onClick={openModal} shape="circle" icon="edit" />
      <Modal
        title={
          <div style={{ textAlign: 'center' }}>
            Complete Profile to Activate 1:1 Professional Intro Calls
          </div>
        }
        centered
        visible={show}
        maskClosable
        style={{ height: 530 }}
        width={
          window.innerWidth <= 900 && window.innerHeight <= 1024 ? '100%' : 1000
        }
        footer={null}
        onCancel={closeModal}
      >
        <div style={{ height: window.innerWidth <= 850 ? '450px' : '250px' }}>
          <ProfileForm closeModal={closeModal} />
        </div>
        {/* <Steppers close={closeModal} /> */}
      </Modal>
      <Modal
        style={{ zIndex: '100' }}
        title={<p style={{ color: 'red' }}>Calendar Sync Error Notification</p>}
        footer={false}
        width={900}
        centered
        visible={showPermissionErrorModal}
        onCancel={() => setShowPermissionErrorModal(false)}
      >
        <h1 style={{ textAlign: 'center' }}>
          Please authorize Meeting Machine all Calendar Permissions to
          Successfully Onboard
        </h1>
      </Modal>
    </Fragment>
  )
}

export default observer(OnboardingUserModal)
