import React from 'react'
import { observer } from 'mobx-react-lite'

import { noop } from 'config/utils'

import './CancelSubscription.scss'

const CancelSubscription = ({ handleCancelSubscription }) => {
  return (
    <div className="cancel-subscription-container">
      <h3
        role="presentation"
        onKeyDown={noop}
        onClick={handleCancelSubscription}
      >
        Cancel Subscription
      </h3>
    </div>
  )
}

export default observer(CancelSubscription)
