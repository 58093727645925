import React, { useContext } from 'react'
import {
  UserDetailContext,
  InterestGroupStoreContext,
  JoinGroupStoreContext,
} from 'store'
import { List, Divider, Avatar, Button, message } from 'antd'

export const AdminListComponent = props => {
  const { setShowImageModal, setShowMyGroupInfoModal } = props
  const { user } = useContext(UserDetailContext)
  const { groupDetail } = useContext(InterestGroupStoreContext)
  const { revokeInvitation } = useContext(JoinGroupStoreContext)

  console.log(user, groupDetail)
  return (
    <>
      <Divider orientation="left" />
      {groupDetail && groupDetail.group_owner && (
        <List
          size="small"
          bordered
          dataSource={[
            `${groupDetail.group_owner.firstName} ${
              groupDetail.group_owner.lastName
            }`,
          ]}
          renderItem={item => (
            <List.Item
              actions={[
                <Button disabled key="list-loadmore-edit">
                  Locked
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar src={groupDetail.group_owner?.profileUrl || ''} />
                }
                title={item}
              />
              <div>Owner</div>
            </List.Item>
          )}
        />
      )}
      {groupDetail &&
        groupDetail.invitedAdmins &&
        Boolean(groupDetail.invitedAdmins.length) && (
          <List
            size="small"
            bordered
            dataSource={groupDetail.invitedAdmins}
            renderItem={item => (
              <List.Item
                style={{ marginBottom: '1px' }}
                actions={[
                  <Button
                    disabled={groupDetail.userId === user.id ? false : true}
                    key="list-loadmore-edit"
                    onClick={async () => {
                      let status = revokeInvitation(
                        groupDetail.id,
                        item.admin_id
                      )
                      if (status) {
                        message.success('Invitation revoked successfully')
                        setShowImageModal(false)
                        if (setShowMyGroupInfoModal) {
                          setShowMyGroupInfoModal(false)
                        }
                      }
                    }}
                  >
                    Delete
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item?.profileUrl || ''} />}
                  title={item.admin_name}
                />
                <div>Admin</div>
              </List.Item>
            )}
          />
        )}
    </>
  )
}
