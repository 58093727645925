import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Steps, Button } from 'antd'

import steps from './Steps'

import { UserDetailContext } from 'store'

import { noop } from 'config/utils'
import './Steppers.scss'

const Step = Steps.Step

const Steppers = ({ close }) => {
  const [currentStep, setCurrentStep] = useState(0)

  const { user } = useContext(UserDetailContext)

  const isTitle = user?.title?.length > 0
  const isLocation = user?.location?.[0]?.length > 0
  const isCompany = user?.company?.length > 0
  const isIndustry = user?.industry?.[0]?.length > 0
  // const hasDefaultCalendar = user?.defaultCalendar?.length > 0

  useEffect(() => {
    if (!(isTitle && isLocation && isCompany && isIndustry)) {
      setCurrentStep(0)
    }
  }, [])

  const next = () => {
    setCurrentStep(currentStep + 1)
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  return (
    <div className="steppers-container">
      <Steps
        direction={
          window.innerWidth <= 800 && window.innerHeight <= 1024
            ? 'vertical'
            : 'horizontal'
        }
        current={currentStep}
      >
        {steps({ next }).map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">
        {steps({ next })[currentStep].content}
      </div>
      <div className="actions_parent">
        {currentStep === 0 && (
          <div className="wc_footer_label">
            More info at{' '}
            <a
              href="https://www.meetingmachine.me"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.meetingmachine.me
            </a>{' '}
            or email{' '}
            <a href={`mailto:human@meetingmachine.co`}>
              human@meetingmachine.co
            </a>
          </div>
        )}

        <div className="steps-action">
          {currentStep < steps().length - 1 && (
            <Button
              disabled={
                currentStep === 0
                  ? false
                  : currentStep === 1
                  ? !isTitle && !isLocation && !isCompany && !isIndustry
                  : false
              }
              type="primary"
              onClick={next}
            >
              Next
            </Button>
          )}
          {/* {currentStep === steps().length - 1 && (
            <Button
              type="primary"
              disabled={!(isTitle && isLocation && isCompany && isIndustry)}
              onClick={() => {
                close()
                message.success('Onboarding complete!')
              }}
            >
              Done
            </Button>
          )} */}
          {currentStep > 0 && (
            <Button className="previous-btn" onClick={prev}>
              Previous
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

Steppers.propTypes = {
  close: PropTypes.func,
}

Steppers.defaultProps = {
  close: noop,
}

export default Steppers
