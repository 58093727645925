import React from 'react'

import ProfileForm from '../ProfileForm/ProfileForm'
// import SocialCalendar from '../SocialCalendar/SocialCalendar'
import Welcome from '../Welcome'

export default props => [
  {
    title: 'Welcome.',
    content: <Welcome {...props} />,
  },
  {
    title: 'Profile Information.',
    content: <ProfileForm {...props} />,
  },
  // {
  //   title: 'Link your calendar.',
  //   content: <SocialCalendar {...props} />,
  // },
]
