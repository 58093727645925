import { post } from './index'

export const billingService = async (token, brand, lastDigits, email) => {
  const response = await post({
    url: '/payment/create-subscription',
    body: { token, brand, lastDigits, email },
  })
  return response.data || {}
}

export const cancelSubscriptionService = async () => {
  const response = await post({
    url: '/payment/delete-subscription',
  })
  return response.data || {}
}

export const updateCustomerCardService = async (
  token,
  brand,
  lastDigits,
  email
) => {
  const response = await post({
    url: '/payment/update-customer-card',
    body: { token, brand, lastDigits, email },
  })
  return response.data || {}
}

export const fake = () => {}
