import { get, post } from './index'

export const getAuthorizationService = async () => {
  const response = await post({
    url: `/signin/geturl`,
  })
  return response.data || {}
}

export const getAuthURLService = async authName => {
  const response = await get({
    url: `/${authName}/get-auth-url`,
  })
  return response.data || {}
}

export const fake = () => {}
