import { createContext } from 'react'
import { observable, decorate, action } from 'mobx'
import {
  billingService,
  cancelSubscriptionService,
  updateCustomerCardService,
} from '../services/billingService'

class Billing {
  payment = null

  makePayment = async (token, brand, lastDigits, email) => {
    this.payment = await billingService(token, brand, lastDigits, email)
    return this.payment
  }

  cancelSubscription = async () => {
    this.payment = await cancelSubscriptionService()
    return this.payment
  }

  updateCustomerCard = async (token, brand, lastDigits, email) => {
    this.payment = await updateCustomerCardService(
      token,
      brand,
      lastDigits,
      email
    )
    return this.payment
  }
}

decorate(Billing, {
  payment: observable,
  makePayment: action,
  cancelSubscription: action,
  updateCustomerCard: action,
})

export default createContext(new Billing())
