import { lazy } from 'react'

export const routes = {
  // home: {
  //   path: '/',
  //   exact: true,
  //   component: lazy(() => import('../pages/HomePage/HomePage')),
  // },

  // Commenting this as per the requirement in Sprint March 2024
  // deactivate create group page (keeping this for future reference)
  // home_with_group: {
  //   path: '/signup-community',
  //   exact: true,
  //   component: lazy(() => import('../pages/LoginWithNewGroup/HomePage')),
  // }

  dashboard: {
    path: '/dashboard',
    exact: true,
    component: lazy(() => import('../pages/DashboardPage/DashboardPage')),
  },
  // ondemand: {
  //   path: '/ondemand',
  //   exact: true,
  //   component: lazy(() => import('../pages/handleOnDemandRoute')),
  // },
  conference: {
    path: '/conference/:conferencePin',
    exact: true,
    component: lazy(() => import('../pages/handleOnDemandRoute')),
  },
  updateUser: {
    path: '/update-user',
    exact: true,
    component: lazy(() => import('../pages/UpdateUser/UpdateUserPage')),
  },
  intermediate: {
    path: '/intermediate',
    exact: true,
    component: lazy(() => import('../pages/IntermediatePage/IntermediatePage')),
  },
  group: {
    path: '/group/:id/:inviteToken?',
    exact: true,
    component: lazy(() => import('../pages/GroupPage/GroupPage')),
  },
  login: {
    path: '/login',
    exact: true,
    component: lazy(() => import('../components/Login')),
  },
  signup: {
    path: '/signup',
    exact: true,
    component: lazy(() => import('../pages/Signup/signup')),
  },
  introProfile: {
    path: '/introLink/:profileId',
    exact: true,
    component: lazy(() => import('../pages/IntroProfile/IntroProfile')),
  },
}

export const renderRoutes = Object.entries(routes)
