export const images = {
  app__logo: require('../assets/images/app__logo.png'),
  app__logo_small: require('../assets/images/favicon.png'),
  signin__button: require('../assets/images/signin__button.png'),
  app_No_Image: require('../assets/images/app_No_Image.png'),
  app__logo_linkedin: require('../assets/images/linkedin-icon.png'),
  app__close_icon: require('../assets/images/close_icon.svg'),
  call_icon_red: require('../assets/images/call-icon-red.svg'),
  call_icon_green: require('../assets/images/call-icon-green.svg'),
  mute_call: require('../assets/images/mute.png'),
  unmute_call: require('../assets/images/unmute.png'),

  app__conference_icon: require('../assets/images/conference-img.png'),
  app__user_icon: require('../assets/images/user-img.png'),
  app__user: require('../assets/images/avatar.jpg'),
  app__user_loder: require('../assets/images/User.gif'),
}
