import React, { useState, useEffect } from 'react'
import {
  Table,
  Button,
  Modal,
  Input,
  notification,
  Popconfirm,
  Form,
  Select,
  message,
} from 'antd'
import {
  createMatchMakerGroupLink,
  deleteMatchMakerGroupDetails,
  getAllIntentsService,
  getMatcherGroupDetails,
  getMatcherGroupDetailsById,
  updateMatchMakerGroupDetails,
} from 'services/userDetailService'
import { CopyTwoTone } from '@ant-design/icons'

export const CreateMatchmakerRules = ({ group }) => {
  const [showCreateMatchmakerModal, setShowCreateMatchmakerModal] = useState(
    false
  )

  const [editMatchmaker, setEditMatchmaker] = useState(null)
  const [matchMakerData, setMatchMakerData] = useState(null)
  const [allIntents, setAllIntents] = useState([])
  const [filterIntents, setFilterIntents] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [group1name, setGroup1name] = useState('')
  const [group2name, setGroup2name] = useState('')
  const [group1intents, setGroup1intents] = useState([])
  const [group2intents, setGroup2intents] = useState([])
  const [premiumUserWaringModel, setPremiumUserWaringModel] = useState(false)
  const [macherId, setMacherId] = useState(null)
  const [isCopied, setIsCopied] = useState(false)

  const handleCopyText = async link => {
    try {
      let copyLinks = `https://${link}`
      navigator.clipboard.writeText(copyLinks)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    } catch (e) {
      notification.error({ message: 'Something went wrong' })
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const intents = await getAllIntentsService()
        const getMatchmaker = await getMatcherGroupDetails({
          group_id: group.id,
        })
        setMatchMakerData(getMatchmaker.data)
        setAllIntents(intents)
        setFilterIntents(intents)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const getEditMatchmakerData = async id => {
    setMacherId(id)
    const editData = await getMatcherGroupDetailsById({ matcher_id: id })
    if (editData.data) {
      setShowCreateMatchmakerModal(true)
      setEditMatchmaker(
        editData.data.matcherLinks.map(item => {
          return {
            ...item,
            intents: item.intents.map(v => v.id),
          }
        })
      )
    } else {
      setEditMatchmaker([])
    }
  }
  const handleSubmit = async e => {
    e.preventDefault()
    if (!editMatchmaker) {
      if (
        group1name !== '' &&
        group2name !== '' &&
        group1intents?.intent?.length !== 0 &&
        group2intents?.intent?.length !== 0 &&
        group1intents?.length !== 0 &&
        group2intents?.length !== 0
      ) {
        setIsLoading(true)
        const data = {
          group_id: group.id,
          name1: group1name,
          name2: group2name,
          intents1: group1intents.intent,
          intents2: group2intents.intent,
        }
        try {
          const res = await createMatchMakerGroupLink(data)
          if (res) {
            setIsLoading(false)
            const getMatchmaker = await getMatcherGroupDetails({
              group_id: group.id,
            })
            setMatchMakerData(getMatchmaker.data)
            setGroup1name('')
            setGroup2name('')
            setGroup1intents([])
            setGroup2intents([])
            setShowCreateMatchmakerModal(false)
            notification.success({
              message: 'Match Maker Group Created.',
            })
          }
        } catch (e) {
          const { statuscode, message } = e.response.data
          setIsLoading(false)
          if (statuscode !== 200) {
            setIsLoading(false)
            notification.error({
              message,
            })
          }
        }
      } else {
        setIsLoading(false)
        return message.warning('please fill all the field')
      }
    } else {
      setIsLoading(true)
      const data = {
        matcher_id: macherId,
        data1: {
          id: editMatchmaker[0].id,
          name: editMatchmaker[0].matcherName,
          intents: editMatchmaker[0].intents,
        },
        data2: {
          id: editMatchmaker[1].id,
          name: editMatchmaker[1].matcherName,
          intents: editMatchmaker[1].intents,
        },
      }
      if (
        editMatchmaker[0].matcherName !== '' &&
        editMatchmaker[1].matcherName !== '' &&
        editMatchmaker[0].intents.length !== 0 &&
        editMatchmaker[1].intents.length !== 0
      ) {
        try {
          const res = await updateMatchMakerGroupDetails(data)
          if (res) {
            setIsLoading(false)

            const getMatchmaker = await getMatcherGroupDetails({
              group_id: group.id,
            })
            setMatchMakerData(getMatchmaker.data)
            setShowCreateMatchmakerModal(false)
            notification.success({
              message: 'Match Maker Group updated.',
            })
          }
        } catch (e) {
          const { statuscode, message } = e.response.data
          setIsLoading(false)
          if (statuscode !== 200) {
            setIsLoading(false)
            notification.error({
              message,
            })
          }
        }
      } else {
        setIsLoading(false)
        return message.warning('please fill all the field')
      }
    }
  }

  const handleSelect1Search = value => {
    const temp = allIntents.filter(option =>
      option.name.toLowerCase().includes(value.toLowerCase())
    )
    setFilterIntents(temp)
  }
  const handleSelect2Search = value => {
    const temp = allIntents.filter(option =>
      option.name.toLowerCase().includes(value.toLowerCase())
    )
    setFilterIntents(temp)
  }

  const userGroupColumn = [
    {
      title: 'Match Groups​',
      align: 'center',
      render: (value, record) => {
        return (
          <>
            <div>{record.matcherLinks[0].matcherName}</div>
            <div>+</div>
            <div>{record.matcherLinks[1].matcherName}</div>
          </>
        )
      },
    },
    {
      title: 'Link',
      align: 'center',
      render: (value, record) => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: '10px',
              }}
            >
              <a href={`https://${record.matcherLinks[0].matcherLink}`}>
                {' '}
                <div id="intro-link">{record.matcherLinks[0].matcherLink}</div>
              </a>
              <CopyTwoTone
                width={50}
                height={50}
                onClick={() =>
                  handleCopyText(record.matcherLinks[0].matcherLink)
                }
              />
            </div>
            <div />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: '10px',
              }}
            >
              <a href={`https://${record.matcherLinks[1].matcherLink}`}>
                {' '}
                <div id="intro-link">{record.matcherLinks[1].matcherLink}</div>
              </a>
              <CopyTwoTone
                width={50}
                height={50}
                onClick={() =>
                  handleCopyText(record.matcherLinks[1].matcherLink)
                }
              />
            </div>
          </>
        )
      },
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          {group.isPremium ? (
            <Button
              style={{ margin: '2px' }}
              onClick={() => {
                getEditMatchmakerData(record.id)
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              style={{ margin: '2px' }}
              onClick={() => setPremiumUserWaringModel(true)}
            >
              Edit
            </Button>
          )}

          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={async () => {
              try {
                const data = {
                  matcher_id: record.id,
                }
                setLoading(true)
                await deleteMatchMakerGroupDetails(data)
                const getMatchmaker = await getMatcherGroupDetails({
                  group_id: group.id,
                })
                setMatchMakerData(getMatchmaker.data)
                setShowCreateMatchmakerModal(false)
                notification.success({
                  message: 'Match maker deleted successfully',
                })
              } finally {
                setLoading(false)
              }
            }}
          >
            <Button danger style={{ margin: '2px' }}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ]
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          margin: '2px',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <h4>Matchmaker Group Link</h4>
        {isCopied && message.success('Copied')}
        <Table
          style={{
            height: '180px',
            overflow: 'scroll',
            width: '-webkit-fill-available',
            margin: '2px',
          }}
          bordered
          loading={loading}
          scroll={{ x: 400 }}
          pagination={false}
          rowKey={'id'}
          columns={userGroupColumn}
          dataSource={matchMakerData}
        />
        {group.isPremium ? (
          <Button
            style={{ margin: '2px' }}
            onClick={() => setShowCreateMatchmakerModal(true)}
          >
            Generate new Link​{' '}
          </Button>
        ) : (
          <Button
            style={{ margin: '2px' }}
            onClick={() => setPremiumUserWaringModel(true)}
          >
            Generate new Link​{' '}
          </Button>
        )}
      </div>
      <Modal
        title={
          editMatchmaker ? 'Edit Matchmaker Rules' : 'Create Matchmaker Rules'
        }
        footer={false}
        width="600px"
        visible={showCreateMatchmakerModal}
        onOk={console.log}
        onCancel={() => {
          setShowCreateMatchmakerModal(false)
          setEditMatchmaker(null)
        }}
      >
        <div>
          <Form key={editMatchmaker} onSubmit={handleSubmit}>
            <div
              className="group1"
              style={{ display: 'flex', gap: '40px', padding: '20px' }}
            >
              <Input
                name="group1name"
                style={{ width: '65%' }}
                key="key1"
                value={
                  editMatchmaker ? editMatchmaker?.[0]?.matcherName : group1name
                }
                placeholder="Enter Match group 1 name"
                onChange={e => {
                  if (editMatchmaker) {
                    const { value } = e.target
                    setEditMatchmaker(prevData => {
                      const newData = [...prevData]
                      newData[0] = {
                        ...newData[0],
                        matcherName: value,
                      }
                      return newData
                    })
                  } else {
                    setGroup1name(e.target.value)
                  }
                }}
              />
              <Select
                mode="multiple"
                key="select1"
                name="select1"
                placeholder="Select up to 3 intro intents​"
                onSearch={handleSelect1Search}
                filterOption={false}
                value={
                  editMatchmaker
                    ? editMatchmaker?.[0]?.intents
                    : group1intents?.intent
                }
                defaultValue={
                  editMatchmaker
                    ? editMatchmaker?.[0]?.intents
                    : group1intents?.intent
                }
                onChange={(value, options) => {
                  if (value?.length > 3)
                    return message.warning(
                      'Only up to three intro Tags are allowed.'
                    )
                  else {
                    if (editMatchmaker) {
                      setEditMatchmaker(prevData => {
                        const newData = [...prevData]
                        newData[0] = {
                          ...newData[0],
                          intents: value,
                        }
                        return newData
                      })
                    } else {
                      setGroup1intents({
                        ...group1intents,
                        intent: options.map(v => v?.key),
                      })
                    }
                  }
                }}
              >
                {filterIntents?.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    <span>{name}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div
              className="group2"
              style={{ display: 'flex', gap: '40px', padding: '20px' }}
            >
              <Input
                style={{ width: '65%' }}
                name="group2name"
                key="key2"
                value={
                  editMatchmaker ? editMatchmaker?.[1]?.matcherName : group2name
                }
                placeholder="Enter Match group 2 name"
                onChange={e => {
                  if (editMatchmaker) {
                    const { value } = e.target
                    setEditMatchmaker(prevData => {
                      const newData = [...prevData]
                      newData[1] = {
                        ...newData[1],
                        matcherName: value,
                      }
                      return newData
                    })
                  } else {
                    setGroup2name(e.target.value)
                  }
                }}
              />

              <Select
                mode="multiple"
                key="select2"
                name="select2"
                onSearch={handleSelect2Search}
                filterOption={false}
                placeholder="Select up to 3 intro intents​"
                value={
                  editMatchmaker
                    ? editMatchmaker?.[1]?.intents
                    : group2intents?.intent
                }
                defaultValue={
                  editMatchmaker
                    ? editMatchmaker?.[1]?.intents
                    : group2intents?.intent
                }
                onChange={(value, options) => {
                  if (value?.length > 3)
                    return message.warning(
                      'Only up to three intro Tags are allowed.'
                    )
                  else {
                    if (editMatchmaker) {
                      setEditMatchmaker(prevData => {
                        const newData = [...prevData]
                        newData[1] = {
                          ...newData[1],
                          intents: value,
                        }
                        return newData
                      })
                    } else {
                      setGroup2intents({
                        ...group2intents,
                        intent: options.map(v => v?.key),
                      })
                    }
                  }
                }}
              >
                {filterIntents?.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    <span>{name}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ textAlign: 'center', padding: ' 20px' }}>
              <Button htmlType="submit" type="primary" loading={isLoading}>
                {editMatchmaker ? 'Save' : 'Create'}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Unlock Premium Community Building Features"
        centered
        width={500}
        visible={premiumUserWaringModel}
        onCancel={() => setPremiumUserWaringModel(false)}
        footer={null}
      >
        <>
          <div className="container">
            <p style={{ fontSize: '16px', marginBottom: '10px' }}>
              Schedule a call with our solutions team to discuss best options
              and platform features to harness for your community building
              goals.
            </p>
            <Button>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/meeting-machine/meeting-machine-intros-demo"
              >
                Schedule Call
              </a>
            </Button>
          </div>
        </>
      </Modal>
    </>
  )
}
