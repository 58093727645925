import React from 'react'

import { Logo, Spinner } from 'components'
import './IntermediateComponent.scss'

const IntermediateComponent = ({ loading, content }) => (
  <div className="intermediate-container">
    <Logo />
    <div className="statement">
      <Spinner spinning={loading} />
      <h2>{content}</h2>
    </div>
  </div>
)

export default IntermediateComponent
